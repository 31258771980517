<template>
  <div>
    <Navbar />
    <section class="customContainer">
      <slot />
    </section>
    <YmiNotifications
      :mapped-store="notieStore"
      transition-name="scale-in"
    />
  </div>
</template>
<script setup>
import { useNotieStore } from '~/stores/notifications';

const notieStore = useNotieStore();
</script>
