<template>
  <nav class="flex items-center justify-between p-4">
    <NuxtLink to="/">
      <img
        src="~/assets/icons/denginadom.svg"
        alt="Деньги на дом"
        width="150"
        height="33"
      />
    </NuxtLink>
    <YmiDropdown
      place="bottom-left"
      menu-class="translate-y-2"
    >
      <template #label>
        <YmiButton class="mainBtn text-sm">
          {{ initials }}
          <Icon
            tabindex="0"
            name="arrow"
            size="xs"
            class="rotate-90"
          />
        </YmiButton>
      </template>

      <ul class="flex flex-col justify-center gap-1">
        <li
          class="cursor-pointer rounded-lg py-2 px-3 transition-all hover:bg-gray-100"
          @click="authStore.logout()"
        >
          Выйти
        </li>
      </ul>
    </YmiDropdown>
  </nav>
</template>

<script setup>
import { useAuthStore } from '~/stores/auth';

const authStore = useAuthStore();

const initials = computed(() => {
  const res = (authStore?.user?.user_name || '').replace(/\s+/g, ' ');
  const splitted = res.split(' ').filter((i) => i.match(/[а-яё_-]/));

  if (splitted.length === 3) {
    return splitted
      .map((word, index) => (index ? word.substring(0, 1).toUpperCase() + '.' : word))
      .join(' ');
  }

  return res;
});
</script>
